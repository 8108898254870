import { ChannelCampaign } from './ChannelCampaign';

export enum ChannelType {
  CPA = 'cpa',
  CPS = 'cps',
  CPC = 'cpc',
  CPM = 'cpm',
  SOCIAL = 'social'
}

export interface Channel {
  id: number
  type: ChannelType
  utm_source: string
  created_at: string
  updated_at: string
  campaigns_count: number
  admins_count: number
  campaigns: ChannelCampaign[]
}

export type PartialChannel = Partial<Channel>;
