
import { defineComponent, ref, unref, toRefs, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';

import { useCreateChannel, useUpdateChannel, useChannel } from '@/composables/api';
import { PartialChannel, ChannelType } from '@/interfaces/Channel';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialChannel = {
  utm_source: '',
  type: ChannelType.CPA
};

const rules: Rules = {
  utm_source: [
    {
      required: true
    }
  ],
  type: [
    {
      required: true
    }
  ]
};

const TYPE_OPTIONS = Object
  .entries(ChannelType)
  .map(([label, value]) => ({
    label, value
  }));

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const channelId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateChannel();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateChannel();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useChannel({ channelId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { channelId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    formValues.value = DEFAULT_FORM_VALUES;
                    router.go(-1);
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  router.replace({
                    name: 'list-channels'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      TYPE_OPTIONS,
      bindFormItemError
    };
  }
});
